import React, { useMemo } from "react"
import { graphql } from "gatsby"

import { useShopify } from "../hooks/useShopify"
import { useSale } from "../hooks/useSale"

import { Sections as Page } from "../components/Sections/Sections"

import { useHotjar } from "../hooks/useHotjar"
import { useCore } from "../hooks/useCore"
import { useApp } from "../hooks/useApp"
import { useLocation } from "../hooks/useLocation"

export const query = graphql`
  query {
    homepages: allSanityPageHomepage(filter: {_id: {regex: "/^(?<!drafts\\.)[^drafts\\.].+$/"}}) {
      edges {
        node {
          handle {
            current
          }
          metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
          content {
            ... on SanitySectionProductsCarousel {
              ...PAGE_SECTION_PRODUCTS_CAROUSEL
            }
            ... on SanitySectionFeaturedContentCarousel {
              ...PAGE_SECTION_FEATURED_CONTENT_CAROUSEL
            }
            ... on SanitySectionImageGrid {
              ...PAGE_SECTION_IMAGE_GRID
            }
            ... on SanitySectionHero {
              ...SANITY_SECTION_HERO
            }
            ... on SanitySectionHeroCarousel {
              ...SANITY_SECTION_HERO_CAROUSEL
            }
            ... on SanitySectionImageText {
              ...PAGE_SECTION_IMAGE_TEXT
            }
            ... on SanitySectionRichText {
              ...PAGE_SECTION_RICH_TEXT
            }
            ... on SanitySectionInstagram {
              ...PAGE_SECTION_INSTAGRAM
            }
            ... on SanitySectionImageContentCarousel {
              ...PAGE_SECTION_IMAGE_CONTENT_CAROUSEL
            }
            ... on SanitySectionEmarsysForm {
              ...PAGE_SECTION_EMARSYS_FORM
            }                
          }
        }
      }
    }
    sanitySettingPromotions {
      activeHomepage {
        handle {
          current
        }
      }
    }
    promotions: allSanityPromotion {
      edges {
        node {
          title
          slug {
            current
          }
          promotion {
            desktopHeader: _rawDesktopHeader(
              resolveReferences: { maxDepth: 6 }
            )
            mobileHeader: _rawMobileHeader(resolveReferences: { maxDepth: 6 })
            homepage: _rawHomepage(resolveReferences: { maxDepth: 6 })
          }
        }
      }
    }
  }
`

const Component = ({ data, ...props }) => {
  const {
    config: {
      settings: { params },
    },
  } = useApp()
  const {
    helpers: { getUrlParameter },
  } = useCore()
  const { getOnSale } = useSale()
  const { edgeNormaliser } = useShopify()
  const preview = useMemo(() => getUrlParameter(params.preview), [getUrlParameter, params.preview])
  const promotions = data?.promotions
    ? edgeNormaliser(data?.promotions).reduce(
        (result, item) =>
          item?.slug?.current
            ? {
                ...result,
                [item.slug.current]: {
                  metadata: item?.promotion?.homepage?.metadata,
                  content: item?.promotion?.homepage?.content,
                },
              }
            : result,
        {},
      )
    : {}
  const saleName = getOnSale()

  const { trackHotjarEvent } = useHotjar()

  const { location } = useLocation()

  const page = useMemo(() => {
    const handle = preview || data?.sanitySettingPromotions?.activeHomepage?.handle?.current

    const localeSuffix = location === "NZ" ? `-${location?.toLocaleLowerCase()}` : ""

    if (saleName && promotions[saleName + localeSuffix]?.content?.length) {
      return promotions[saleName]
    } else if (saleName && promotions[saleName]?.content?.length) {
      return promotions[saleName]
    } else {
      return data?.homepages?.edges?.find((edge: any) => edge?.node?.handle?.current === handle)?.node
    }
  }, [data?.homepages?.edges, data?.sanitySettingPromotions?.activeHomepage?.handle, preview, promotions, saleName, location])

  trackHotjarEvent("index_page")

  return (
    <Page
      isHomepage
      {...props}
      page={page}
    />
  )
}
export default Component

